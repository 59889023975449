<template>
    <div>
        <div class="add">
            <el-button @click="add('new','')">添加部位</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="部位"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="add('bj',scope.row)">编辑 </el-button>
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
                    <el-button size="mini" type="warning" icon="el-icon-search" @click="addzz(scope.row)">查看症状 </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p> -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form class="gdtc" label-width="170px">
                <el-form-item label="部位">
                    <el-input v-model="bw" autocomplete="off" placeholder="请输入部位"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;bw = ''">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                dialogFormVisible: false,
                title: '添加部位',
                type: '',
                bw: '',
                item: ''
            }
        },
        mounted() {
            this.axios.get("/gu/get_gu_part")
                .then((rs) => {
                    // console.log(rs.data)
                    if (rs.data.code == 0) {
                        this.list = rs.data.result;
                    } else {
                        console.log(rs)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        methods: {
            add(type, item) {
                this.dialogFormVisible = true;
                this.type = type;
                this.item = item;
                if (type != 'new') {
                    this.title = '编辑部位';
                    this.bw = item.name;
                } else {
                    this.title = '添加部位';
                    this.bw = '';
                }
            },
            addzz(item) {
                // console.log(item)
                this.$router.push({ path: '/zzManage', query: { name: item.name, id: item.id } });
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该部位, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_part", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            handleSizeChange() { },
            handleCurrentChange() { },
            sub() {
                let url = '';
                let form = {
                    name: this.bw
                }
                let str = '';
                if (this.type == 'new') {
                    url = "/gu/insert_gu_part";
                    str = '添加成功'
                } else {
                    url = "/gu/update_gu_part";
                    str = "修改成功";
                    form.id = this.item.id;
                }
                // console.log(form);
                this.axios.post(url, this.qs.stringify(form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.dialogFormVisible = false;
                            this.form = {};
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .gdtc {
        width: 80%;
    }
</style>